<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Billing and Subscription" />

    <div>
      <v-card 
        :loading="status.subscribing || status.updating || status.gettingPreview" 
        :disabled="status.subscribing || status.updating || status.gettingPreview"  
        v-show="!$store.getters['pricing/activeCount'].length || change" 
        class="mb-5 shadow-lg"
      >
        <v-btn 
          v-if="$store.getters['pricing/withActiveSub']" 
          @click="change = false" 
          class="mr-n7 mt-1"
          absolute
          depressed
          small
          right 
          fab
          top
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-card-text class="pa-7">
          <h3 class="font-weight-black text-center text-h5 mb-7">Choose the right plan for your needs</h3>

          <p v-if="packageIntroduction">
            {{ packageIntroduction._value }}
          </p>

          <v-row>
            <v-col 
              v-for="price in $store.getters['pricing/activePrices']" 
              :key="price.id"
              style="position: relative"
            >
              <div 
                v-if="isBestValue(price)"
                class="text-center mt-n3"
                style="position: absolute; width: 100%;"
              >
                <v-chip
                  style="z-index: 2 !important"
                  color="accent gradient"
                >Best Value</v-chip>
              </div>
              
              <v-card class="shadow-lg">
                <v-card-title class="heading-font font-weight-black primary--text justify-start">
                  <span class="text-capitalize">{{ price.metadata.title }}</span>
                </v-card-title>
                <v-card-text class="pb-5">
                  <div class="d-flex align-center mb-5 gap-10">
                    <div class="text-h3 font-weight-bold primary--text">
                      ${{ price.unit_amount | amountNoneDecimal }}
                    </div>
                    <span>/</span>
                    <div>
                      RRP
                      <br>
                    </div>
                  </div>
                  
                  <div class="text-left body-lg primary--text text-pre-line" style="line-height: 1.5">
                    {{ price.metadata.description }}
                  </div>

                  <div class="text-center mt-5">
                    <v-btn
                      v-if="!$store.getters['pricing/activeCount'].length"
                      @click="subscribe(price)" 
                      color="accent gradient" 
                      block
                    >
                      Subscribe
                    </v-btn>
                    <v-btn
                      v-if="$store.getters['pricing/activeCount'].length && !current(price.id)"
                      @click="updateSub(price)" 
                      color="accent gradient" 
                      block
                    >
                      Select
                    </v-btn>
                    
                    <v-btn
                      v-if="current(price.id)"
                      color="accent gradient" 
                      disabled
                      block
                    >
                      Current
                    </v-btn>
                  </div>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-sheet max-width="550" class="mx-auto text-center mt-5" :style="{ fontSize: '0.9rem' }"> 
            {{ bottomDescription._value }}
          </v-sheet>
        </v-card-text>
      </v-card>

      <v-card v-if="subscriptions.length" :loading="status.subscribing || status.updating || status.gettingPreview" :disabled="status.subscribing || status.updating || status.gettingPreview" class="shadow-lg mb-5">
        <preloader v-if="status.gettingSubs" message="Please wait..." />
        <v-card-text v-if="!status.gettingSubs">
          <div class="mb-5 default-size primary--text heading-font font-weight-bold">Recent</div>
          <v-simple-table v-if="subscriptions.length">
            <thead>
              <tr>
                <th>Name</th>
                <th>Created</th>
                <th>Next Billing Period</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sub in subscriptions" :key="sub.id">
                <td class="text-capitalize">{{ $store.getters['pricing/getName'](sub.price.id) }}</td>
                <td>{{ sub.created.seconds * 1000 | formatDate  }}</td>
                <td> <span v-if="sub.status == 'canceled'">Active until</span> {{ sub.current_period_end.seconds * 1000 | formatDate }}</td>
                <td>${{ $store.getters['pricing/getAmount'](sub.price.id) | amount }} <span class="text-uppercase">{{ $store.getters['pricing/getCurrency'](sub.price.id) }}</span> / {{ $store.getters['pricing/getIntervalCount'](sub.price.id) }} <span v-if="$store.getters['pricing/getInterval'](sub.price.id)">{{ pluralize($store.getters['pricing/getInterval'](sub.price.id), $store.getters['pricing/getIntervalCount'](sub.price.id)) }}</span></td>
                <td :class="statusColor(sub.status)">{{ sub.status }}</td>
                <td class="text-right">
                  <v-btn 
                    v-if="sub.status == 'active'"
                    @click="
                      change = true
                      toUpdate = sub.id
                    "
                    color="primary"
                    class="mr-1"
                    small
                  >
                    Change
                  </v-btn>
                  
                  <v-btn 
                    v-if="sub.status == 'active'" 
                    @click="cancelSub(sub)" 
                    small text
                  >Cancel</v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-card v-if="invoices.length" class="shadow-lg">
        <v-card-text>
          <div class="mb-5 default-size primary--text heading-font font-weight-bold">Invoices</div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Amount Paid</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="invoice in invoices"
                  :key="invoice.id"
                >
                  <td>{{ invoice.number }}</td>
                  <td>${{ invoice.amount_paid | amount }} <span class="text-uppercase">{{ invoice.currency }}</span></td>
                  <td>{{ (invoice.created * 1000) | formatDate }}</td>
                  <td>{{ invoice.status }}</td>
                  <td class="text-right">
                    <a 
                      :href="invoice.hosted_invoice_url" 
                      target="_blank"
                      class="info--text text-decoration-none underline-on-hover"
                    >
                      Invoice <v-icon color="info" class="ml-1" small>mdi-chevron-right</v-icon>
                    </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <!-- CONFIRM CANCEL SUBSCRIPTION -->
    <confirm-delete
      :show="confirmCancel"
      :deleting="status.cancelling"
      message="Are you sure you want to cancel your subscription?"
      @cancel="closeCancelDialog()"
      @confirmed="cancelConfirmed()"
    />

    <!-- SHOW PREVIEW DIALOG -->
    <v-dialog
      :value="status.showPreview"
      :max-width="$dialog.medium"
      no-click-animation
      persistent
    >
      <v-card>
        <v-btn @click="$store.commit('pricing/showPreviewState', false)" icon absolute right top>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="font-weight-black text-h5 primary--text heading-font">Update Subscription</span>
        </v-card-title>
        <v-card-text class="pt-3">
          <custom-alert :value="status.downgrading">
            Your plan change will come into effect at the start of your next billing date
          </custom-alert>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Payment Interval</th>
                  <th>Amount Due</th>
                  <th>Billing Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ price.description }}</td>
                  <td>
                    ${{ price.unit_amount | amount }} <span class="text-uppercase">{{ price.currency }}</span> / {{ price.interval_count }} {{ pluralize(price.interval, price.interval_count) }}
                  </td>
                  <td>${{ status.preview.data.total | amount }} <span class="text-uppercase">{{ status.preview.data.currency }}</span> </td>
                  <td>{{ status.preview.data.period_start * 1000 | formatDate }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider></v-divider>

          <div class="d-flex align-center">
            <v-checkbox 
              v-model="terms"
              dense
            ></v-checkbox>
            <span class="caption">I agree to the Billing Terms, <a href="https://www.teacherfinder.com.au/terms-and-conditions/" target="_blank">Terms of Use</a> and have read and understand the <a href="https://www.teacherfinder.com.au/privacy-policy/" target="_blank">Privacy Policy</a></span>
          </div>

          <v-btn
            @click="confirmUpdate()"
            color="accent gradient"
            :loading="status.updating"
            :disabled="!terms"
          >Confirm</v-btn>
        </v-card-text>
      </v-card> 
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pluralize from 'pluralize'
import db from '@/firebase/init'
import firebase from 'firebase'
import moment from 'moment'

export default {
  metaInfo: {
    title: 'Billing and Subscription'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      pluralize,
      show: false,
      price: null,
      terms: false,
      change: false,
      toCancel: null,
      toUpdate: null,
      confirmCancel: false,
      bottomDescription: '',
      packageIntroduction: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.pricing.status,
      subscriptions: state => state.pricing.subscriptions,
      invoices: state => state.pricing.invoices,
      user: state => state.user.user,
    }),

    actives: function () {
      return this.prices.filter(price => price.active)
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('pricing', [
      'getPackage',
      'subscribe',
      'getSubscriptions',
      'cancelSubscription',
      'updateSubscription',
      'getPreview',
      'getInvoices'
    ]),

    statusColor(status) {
      return status == 'active' ? 'success--text' : 'error--text'
    },

    cancelSub(sub) {
      this.confirmCancel = true
      this.toCancel = sub
    },

    closeCancelDialog() {
      this.confirmCancel = false
      this.toCancel = null
    },

    cancelConfirmed() {
      Promise.all([
        this.cancelSubscription(this.toCancel)
      ])
      .then(() => {
        this.closeCancelDialog()
        this.getSubscriptions()
      })
    },

    updateSub(price) {
      this.price = price
      let data = { sub: this.toUpdate, price: price.id }
      this.getPreview(data)
    },

    confirmUpdate() {
      Promise.all([
        this.updateSubscription({ sub: this.toUpdate, price: this.price.id })
      ])
      .then(() => {
        this.change = false
      })
    },

    current(price) {
      let actives = this.$store.getters['pricing/activeCount'].filter(active => active.price.id == price)
      return !!actives.length
    },

    isBestValue(price) {
      let best = true
      let prices = this.$store.getters['pricing/activePrices']
      let priceMonthly = 0
      
      if (price.interval == 'week') {
        priceMonthly = (price.unit_amount / price.interval_count) * 5
      }
      else {
        priceMonthly = price.interval == 'month' ? (price.unit_amount / price.interval_count) : (price.unit_amount / (price.interval_count * 12))
      }

      prices.forEach(p => {
        let monthly = 0

        if (p.interval == 'month') {
          monthly = p.unit_amount / p.interval_count
        }
        else if (p.interval == 'week') {
          monthly = (p.unit_amount / p.interval_count) * 5
        }
        else {
          monthly = p.unit_amount / (p.interval_count * 12)
        }

        if (monthly < priceMonthly) {
          best = false
        }
      })

      return best
    },

    getRemoteConfig() {
      const remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 15000
      remoteConfig.fetchAndActivate()
      .then(() => {
        this.bottomDescription = remoteConfig.getValue("package_bottom_text")
        this.packageIntroduction = remoteConfig.getValue("package_introduction")
      })
      .catch((err) => {
        console.log(err)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getRemoteConfig()
    this.getPackage()
    this.getSubscriptions()
    this.getInvoices()
    let user = firebase.auth().currentUser

    db.collection('customers').doc(user.uid)
    .collection('subscriptions')
    .onSnapshot(() => {
      this.getSubscriptions()
    })
    
  }
}
</script>